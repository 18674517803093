jQuery(document).ready(function ($) {
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
			'X-Requested-With': 'XMLHttpRequest'
		},
		xhrFields: {
			withCredentials: true
		},
		crossDomain: true
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});

	$(".header-nav-toggle").on("click", function (e) {
		e.preventDefault();
		if (!$(e.target).is(":visible")) {
			return;
		}

		$(".header-nav, .header-nav-toggle").toggleClass("open");
	});

	$('.kviz-kerdesek-form').on('submit', function(e){
		if($(this).find('.kviz-kerdes').length != $(this).find('.kviz-kerdes input[type=radio]:checked').length){
			e.preventDefault();
			$('#kviz_alert_modal').modal('show');
		}
	});

    $('.uzletlista-legkondi table').linkify().find('a').attr('target','_blank');

    var email = document.getElementById("email")
      , confirm_email = document.getElementById("email_confirmation");

    function validateEmail(){
      if(email.value != confirm_email.value) {
        confirm_email.setCustomValidity("A megadott e-mail címeknek meg kell egyezniük");
      } else {
        confirm_email.setCustomValidity('');
      }
    }

    if (email) {
        email.onchange = validateEmail;
    }
    if (confirm_email) {
        confirm_email.onkeyup = validateEmail;
    }

    $('#tipus_lak, #tipus_uzl').change(function(event) {
        if ($(this).val()=="uzleti") {
            $('#uzleti').show().attr('required','required');
            $('#lakossagi').hide().find('input').removeAttr('required');
			$('#lakossagi_lakcim_label').hide();
			$('#uzleti_lakcim_label').show();
        }else{
            $('#uzleti').hide().find('input').removeAttr('required');
            $('#lakossagi').show().attr('required','required');
			$('#lakossagi_lakcim_label').show();
			$('#uzleti_lakcim_label').hide();
        }
    });

    if ($('#tipus_lak, #tipus_uzl').filter(':checked').val()=="uzleti") {
        $('#uzleti').show().attr('required','required');
        $('#lakossagi').hide().find('input').removeAttr('required');
		$('#lakossagi_lakcim_label').hide();
		$('#uzleti_lakcim_label').show();
    }else{
        $('#uzleti').hide().find('input').removeAttr('required');
        $('#lakossagi').show().attr('required','required');
		$('#lakossagi_lakcim_label').show();
		$('#uzleti_lakcim_label').hide();
    }

    // $('a[href$="/kviz/kerdes"]').click(function(event) {
    //     return kviz_click('/kviz/kerdes');
    // });

    // $('a[href$="/szamlafeltoltes/feltoltes"]').click(function(event) {
    //     return kviz_click('/szamlafeltoltes/feltoltes');
    // });

});

$(document).on('submit', 'form.recaptcha', function (e) {
	e.preventDefault();
	grecaptcha.execute($(this).find('.g-recaptcha').data('recaptcha-id'));
});

function recaptchaOnload() {
	$('.g-recaptcha').each(function () {
		var $this = $(this);
		$this.data('recaptcha-id', grecaptcha.render($this[0], $this.data()));
	});
}